<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar></topBar>
		<!-- 面包屑 -->
		<div class="breadcrumb_box w1240">
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item v-if="dataobj">{{dataobj.goodsName}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="xc_goods_detail relative w1240" v-if="dataobj">
			<div class="detail_top">
				<div class="left">
					<el-image 
					   class="imgdiv"
					   :src="imageList[curimg] || require('../assets/static/logo.png')"
					    :preview-src-list="imageList">
					</el-image>
					<div class="swiper relative">
					    <div class="swiper_con">
							<swiper ref="mySwiper1" :options="swiperOptions1">
							    <swiper-slide
							        class="swiperslide"
							        v-for="(item,index) in imageList"
							        :key="index">
									<img class="img" @click="handleImg(index)" :class="{'hover':index==curimg}" :src="item || require('../assets/static/logo.png')" />
							    </swiper-slide>
							</swiper>
						</div>
						<div class="prev pointer fontsize22" @click="slidePrev1"><span class="iconfont icon-you"></span></div>
						<div class="next pointer fontsize22" @click="slideNext1"><span class="iconfont icon-you"></span></div>
					</div>
					<div class="goodsUuid">
						<div class="text1">商品编号：{{dataobj.medicineDrugs.drugsCode || dataobj.goodsUuid}}</div>
					</div>
					<div class="goodsTips">
						<div class="text1">温馨提示:</div>
						<div class="text2">厂家可能会更新商品包装，如图片与实物不符以实物为准！</div>
					</div>
				</div>
				<div class="right">
					<div class="itemobj">
						<div class="label_text">商品名称：</div>
						<div class="titlename"><span style="font-size:20px">{{dataobj.goodsName}}</span></div>
					</div>
					<div class="itemobj" style="background: #FBF0F0;" v-if="isLogin">
						<div class="label_text">供应价：</div>
						<div class="price" v-if="type=='exchange'"><span>{{dataobj.finalPrice}}</span>积分</div>
						<div class="price" v-else>￥<span>{{dataobj.finalPrice}}</span></div>
					</div>
					<div class="itemobj" style="background: #FBF0F0;" v-if="!isLogin">
						<div class="label_text">供应价：</div>
						<div class="price" v-if="type=='exchange'"><span>***</span>积分</div>
						<div class="price" v-else>￥会员登录可见</div>
					</div>
					<div class="itemobj">
						<div class="item1">
							<div class="text1">生产日期：</div>
							<div class="text2">{{dataobj.medicineDrugs.productionDate}}</div>
						</div>
						<div class="item1">
							<div class="text1">有效期：</div>
							<div class="text2">{{dataobj.medicineDrugs.validityDate}}</div>
						</div>
						<div class="item2">
							<div class="text1">生产厂家：</div>
							<div class="text2">{{dataobj.manufacturerName || ""}}</div>
						</div>
						<!-- <div class="item2">
							<div class="text1">类别：</div>
							<div class="text2">{{supervision_class[supervision_class_index]}}</div>
						</div> -->
					</div>
					<div class="text_line"></div>
					<div class="itemobj1">
						<div class="label_text">监管类别：</div>
						<div class="value_box">
							<div class="text ">
								<span>{{supervision_class[supervision_class_index]}}</span>
								
							</div>
						</div>
					</div>
					<div class="itemobj1">
						<div class="label_text">选择规格：</div>
						<div class="value_box">
							<div class="text hover">
								<span>{{dataobj.medicineDrugs.baseSku}}</span>
								<img src="../assets/static/Groupselect.png" />
							</div>
						</div>
					</div>
					<div class="itemobj1" v-if="dataobj.medicineDrugs.erpStockVOList&&dataobj.medicineDrugs.erpStockVOList.length>0">
						<div class="label_text">批号/批次：</div>
						<div class="value_box">
							<div class="text" @click="clickpihao(index)" :class="{'hover':index==pihaoIndex}" v-for="(pihaoitem, index) in dataobj.medicineDrugs.erpStockVOList" :key="index">
								<span>{{pihaoitem.batchid}}</span>
								<img v-if="index==pihaoIndex" src="../assets/static/Groupselect.png" />
							</div>
						</div>
					</div>
					<div class="itemobj1">
						<div class="label_text">单位：</div>
						<div class="value_box">
							<div class="text" @click="clickSku1(index)" :class="{'hover':index==skuindex1}" v-for="(item,index) in dataobj.skuList" :key="index">
								<span>{{item.skuName}}</span>
								<img v-if="index==skuindex1" src="../assets/static/Groupselect.png" />
							</div>
						</div>
					</div>
					<div class="text_line"></div>
					<div class="number_box">
						<div class="label_text">数量：</div>
						<div class="cart-count-box">
							<div @click="changeCartNumber('jian')" class="iconfont icon-jian cart-count-down"></div>
							<input type="number" v-model="skunum"/>
							<div @click="changeCartNumber('jia')" class="iconfont icon-jia cart-count-add"></div>
						</div>
						<div class="kucun_number">库存：{{dataobj.stock}}</div>
						<div class="addcar">
							<div v-if="type=='goods'" class="mixicon iconfont icon-gouwuchekong"></div>
							<div class="text" @click="onOprationClick('car')" v-if="type=='goods'">加入采购车</div>
							<div class="text" @click="onOprationClick('buy')" v-if="type=='tejia'">立即采购</div>
							<div class="text" @click="onOprationClick('buy')" v-if="type=='exchange'">立即换购</div>
							<div class="text" @click="onOprationClick('buy')" v-if="type=='team'">参加团购</div>
						</div>
						<div class="addcar">
							<div class="text" v-if="infoData.fav==0" @click="changeFav(1)">收藏</div>
							<div class="text" v-if="infoData.fav==1" @click="changeFav(0)">取消收藏</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="block16"></div>
			<!-- 详情 -->
			<div class="detail_center">
				<div class="right">
					<div class="titlediv fontsize14">
						<div class="text">浏览过的商品</div>
					</div>
					<div class="conobj" v-if="item.goodsUuid!=dataobj.goodsUuid" v-for="(item,index) in nextPatentVoList" :key="index" @click="clickfootDetail(index)">
						<div class="img"><img :src="item.skuImg || require('../assets/static/logo.png')"/></div>
						<div class="title fontsize14">{{item.goodsName}}</div>
						<!-- <div class="price fontsize14">￥18</div> -->
					</div>
				</div>
				<div class="left">
					<div class="titlediv fontsize14">
						<div class="text">商品说明书</div>
					</div>
					<div class="data_con">
						<div class="fontsize14" v-html="dataobj.body"></div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="block40"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//导入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import  topBar  from "../components/topBar/topBar.vue";
export default {
	components: {
	    swiper,
	    swiperSlide,
		topBar
	},
	data() {
		return {
			uid:"",
			getby:"id",//id 或者 uuid
			type:"",//goods普通商品 tejia exchange team
			swiperOptions1:{
				slidesPerView: 4,
			},
			dataobj:null,
			skuindex1: 0, //选中的一级规格下标
			nextPatentVoList:[],
			skunum:1,//单位数量
			imageList:[],
			curimg:0,//当前下标
			infoData:{
				fav:0,//未收藏
				collectobj:{},//收藏的对象
				isShowShare:false,//分享
				isshowsku:false,//展示规格弹窗
			},
			operaType:"",//操作是加入购物车car。还是快速购买buy
			shareObj:null,
			batchid:null,//批号
			pihaoIndex:0,//批号下标，默认第一个
			supervision_class_index:9,
			supervision_class:['乙类OTC','甲类OTC','RX处方药','一类器械','二类器械','三类器械','非监管器械','保健品','食品','其他']
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = this.$route.query.uid
			this.type = this.$route.query.type
			//id还是uuid查询
			if(this.$route.query.getby){
				this.getby = this.$route.query.getby
			}
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
		this.getsupervision()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName','uuid','phone']),
	},
	methods: {
		getsupervision() {
			var _this = this;
			var params
			this.$mmapi.get('drugs/getSupervisionClass', params).then(function(res) {
				//数据处理
				if(res.code==200){
					_this.supervision_class=res.data;
				}
			})
		},
		//选择批号
		clickpihao(index){
			this.pihaoIndex = index
			this.batchid = this.dataobj.medicineDrugs.erpStockVOList[index].batchid
			console.log(this.batchid)
			var stock = this.dataobj.medicineDrugs.erpStockVOList[index].qty || 0
			this.dataobj.stock = Number(stock)
			//生产日期
			this.dataobj.medicineDrugs.productionDate = this.dataobj.medicineDrugs.erpStockVOList[index].mfgdate
			//有效日期
			this.dataobj.medicineDrugs.validityDate = this.dataobj.medicineDrugs.erpStockVOList[index].udate
		},
		//记录详情
		clickfootDetail(index){
			// 0普通 1特价 2积分 3拼团
			if(this.nextPatentVoList[index].ordersType==0){
				this.$router.push({ path: '/goodsDetail', query: {uid: this.nextPatentVoList[index].goodsUuid,type:"goods",getby:"uuid" }})
				this.$router.go(0);
			}else if(this.nextPatentVoList[index].ordersType==1){
				this.$router.push({ path: '/goodsDetail', query: {uid: this.nextPatentVoList[index].goodsUuid,type:"tejia",getby:"uuid" }})
				this.$router.go(0);
			}else if(this.nextPatentVoList[index].ordersType==2){
				this.$router.push({ path: '/goodsDetail', query: {uid: this.nextPatentVoList[index].goodsUuid,type:"exchange",getby:"uuid" }})
				this.$router.go(0);
			}else if(this.nextPatentVoList[index].ordersType==3){
				this.$router.push({ path: '/goodsDetail', query: {uid: this.nextPatentVoList[index].goodsUuid,type:"team",getby:"uuid" }})
				this.$router.go(0);
			}else{
				this.$message.error("此商品已下架！")
			}
		},
		//点击图片
		handleImg(index){
			this.curimg = index
		},
		//上一页
		slidePrev1() {
		    this.$refs.mySwiper1.swiper.slidePrev();
		},
		//下一页
		slideNext1() {
		    this.$refs.mySwiper1.swiper.slideNext()
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var control = ""
			if(this.type=="goods"){
				control = "medicine/medicineDrugsSku/getById"
			}else if(this.type=="tejia"){
				control = "medicine/medicineDrugsSpecial/getById"
			}else if(this.type=="exchange"){
				control = "medicine/medicineDrugsPoint/getById"
			}else if(this.type=="team"){
				control = "medicine/medicineDrugsGroup/getById"
			}else{
				_this.$util.routerPath("/404")
			}
			var params = {}
			if(this.getby=="uuid"){
				params["goodsUuid"] = this.uid
			}else{
				params["id"] = this.uid
			}
			this.$http.post(control, params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						if(dataobj.medicineDrugs.mainImages&&dataobj.medicineDrugs.mainImages.length>0){
							_this.imageList = dataobj.medicineDrugs.mainImages
							dataobj["productImageUrl"] = _this.imageList[0]
						}else{
							_this.imageList = [require('../assets/static/logo.png')]
							dataobj["productImageUrl"] = require('../assets/static/logo.png')
						}
						
						if(_this.type=="goods"){
							//dataobj["finalPrice"] = dataobj.finalPrice
						}else if(_this.type=="exchange"){
							dataobj["finalPrice"] = dataobj.point
						}else{
							dataobj["finalPrice"] = dataobj.price
						}
						//让img 100%展开
						const regex = new RegExp('<img', 'gi');
						if(dataobj.medicineDrugs.body){
							dataobj["body"] = dataobj.medicineDrugs.body.replace(regex, `<img style="width: 100%;height: auto;display:block;"`);
						}else{
							dataobj["body"] = "<p style='color: #AAAAAA;'>暂无相关信息</p>"
						}
						//多单位
						var skuList = [
							{
								skuName:dataobj.baseUnit,
								skuNum:1,
								canSelct:true,
								//skuImg:dataobj.productImageUrl,
							}
						]
						//启动多单位
						if(dataobj.unitStatus==1&&dataobj.moreUnit){
							var unitlist = dataobj.moreUnit.split(",")
							for(var i=0;i<unitlist.length;i++){
								var unit = unitlist[i].split(":")
								if(unit){
									var obj = {
										skuName:unit[0],
										skuNum:1,
										canSelct:true,
									}
									try{
										obj["skuNum"] = Number(unit[1])
									}catch(e){
										//TODO handle the exception
									}
									skuList.push(obj)
								}
							}
							if(skuList.length>1){
								skuList.shift();
								_this.skunum=skuList[0].skuNum
							}
						}
						dataobj["skuList"] = skuList
						//处理库存
						dataobj.stock = 0
						if(dataobj.medicineDrugs.erpStockVOList){
							//过滤掉,库存为0的不显示
							var erpStockVOList = dataobj.medicineDrugs.erpStockVOList.filter((item) => {
								return item.batchid&&item.batchid.length>0&&item.qty&&item.qty>0; //筛选出匹配数据 得有批号
							});
							dataobj.medicineDrugs.erpStockVOList = erpStockVOList
						}
						//处理库存
						if(dataobj.medicineDrugs.erpStockVOList&&dataobj.medicineDrugs.erpStockVOList.length>0){
							//过滤掉
							var stock = dataobj.medicineDrugs.erpStockVOList[0].qty || 0
							//谁小取谁
							// if(Number(stock)<Number(dataobj.stock)){
							// 	dataobj.stock = stock
							// }
							dataobj.stock = stock
							//生产日期
							dataobj.medicineDrugs.productionDate = dataobj.medicineDrugs.erpStockVOList[0].mfgdate
							//有效日期
							dataobj.medicineDrugs.validityDate = dataobj.medicineDrugs.erpStockVOList[0].udate
							_this.batchid = dataobj.medicineDrugs.erpStockVOList[0].batchid
						}
						_this.dataobj = dataobj
						//判断收藏
						//stock转为判断商品监管分类
						_this.supervision_class_index=dataobj.medicineDrugs.typeId
						if(_this.isLogin){
							_this.isCollection()
						}
						//处理商品足迹
						if(_this.isLogin){
							_this.getFootprint()
							_this.Footprint()
						}
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//判断是否收藏
		isCollection() {
			var _this = this
			var params = {}
			params["goodsUuid"] = this.dataobj.goodsUuid
			params["userUuid"] = this.uuid
			this.$http.post("medicine/medicineDrugsCollection/isCollection", params).then(function(res) {
				if (res.code == 200) {
					if(res.data){
						console.log("已收藏")
						_this.infoData.collectobj = res.data
						//已收藏
						_this.infoData.fav = 1
					}
				} else {
					// _this.$confirm(res.message, '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {}).catch(() => {});
				}
			})
		},
		//收藏
		changeFav(fav){
			var _this = this;
			if(!this.isLogin){
				this.$message.info("请先登录~")
				return false;
			}
			var params = {};
		    var controller = 'medicine/medicineDrugsCollection/resetCollection'
			if(this.type=="goods"){
				params['ordersType'] = 0
			}else if(this.type=="tejia"){
				params['ordersType'] = 1
			}else if(this.type=="exchange"){
				params['ordersType'] = 2
			}else{
				params['ordersType'] = 3
			}
			params['userPhone'] = this.phone;
			params['userUuid'] = this.uuid;
			params['batchid'] = this.batchid
			if(Number(fav)==1){
				params['goodsName'] = this.dataobj.goodsName;
				params['goodsUuid'] = this.dataobj.goodsUuid;
				params['medicineId'] = this.dataobj.medicineId;
				params['medicineUuid'] = this.dataobj.medicineUuid;
				params['skuImg'] = this.dataobj.productImageUrl;
				//收藏
				controller = 'medicine/medicineDrugsCollection/putCollection'
			}else{
				params['collectIds'] = [this.infoData.collectobj.id];
				params['resetAll'] = false
			}
			this.$http.post(controller, params).then(function(res) {
				if (res.code == 200) {
					_this.$message.success("成功~")
					if(Number(fav)==1){
						//已收藏
						_this.infoData.fav = 1
						_this.infoData.collectobj = res.data
					}else{
						_this.infoData.fav = 0
					}
				} else {
					// _this.$confirm(res.message, '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {}).catch(() => {});
				}
			})
		},
		//获取记录商品
		getFootprint(){
			var _this = this
			var params = {};
			params['currentPage'] = 1;
			params['pageSize'] = 4;
			params['userPhone'] = this.phone;
			params['userUuid'] = this.uuid;
			this.$http.post('medicine/medicineDrugsFootprint/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.nextPatentVoList = res.data.records
				}
			})
		},
		//处理足迹
		Footprint(){
			var _this = this
			var params = {};
			if(this.type=="goods"){
				params['ordersType'] = 0
			}else if(this.type=="tejia"){
				params['ordersType'] = 1
			}else if(this.type=="exchange"){
				params['ordersType'] = 2
			}else{
				params['ordersType'] = 3
			}
			params['userPhone'] = this.phone;
			params['userUuid'] = this.uuid;
			params['goodsName'] = this.dataobj.goodsName;
			params['goodsUuid'] = this.dataobj.goodsUuid;
			params['medicineId'] = this.dataobj.medicineId;
			params['medicineUuid'] = this.dataobj.medicineUuid;
			params['skuImg'] = this.dataobj.productImageUrl;
			params['batchid'] = this.batchid
			this.$http.post('medicine/medicineDrugsFootprint/putFootprint', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					
				} else {
					// _this.$confirm(res.message, '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {}).catch(() => {});
				}
			})
		},
		//一级规格
		clickSku1(index){
			var _this = this
			//单规格
			_this.skuindex1 = index;
			var obj = this.dataobj.skuList[index]
			this.skunum = obj.skuNum
		},
		//购物车加减
		changeCartNumber(type){
			if(type=="jia"){
				var newnum = Number(this.skunum) + (1*this.dataobj.skuList[this.skuindex1].skuNum);
				if (this.dataobj.stock < newnum) {
				  //商品无库存
				  this.$message.error('库存不足');
				  return;
				}
				this.skunum = newnum
			}else{
				var newnum1 = Number(this.skunum) - (1*this.dataobj.skuList[this.skuindex1].skuNum);
				if (newnum1<=0) {
				   return;
				}
				this.skunum = newnum1
			}
		},
		//购买或者加入购物车
		onOprationClick(type){
			if(!this.isLogin){
				this.$message.info("请先登录~")
				return false;
			}
			if (this.dataobj.stock < Number(this.skunum)) {
			  //商品无库存
			  this.$message.error('库存不足');
			  return false;
			}
			this.operaType = type
			if(type=="buy"){
				var carts = [
					{
						skuName:this.dataobj.skuList[this.skuindex1].skuName,
						skuNum:this.dataobj.skuList[this.skuindex1].skuNum,
						skuImg:this.dataobj.productImageUrl,
						cartId:null,
						goodsUuid:this.dataobj.goodsUuid,
						goodsobj:this.dataobj,
						goodsNum:this.skunum,
						goodsPirce:this.dataobj.finalPrice,
						batchid:this.batchid,
					}
				]
				//立即购买
				var orderList = [{
					logo: "/static/logo.png",
					storeName:this.bsnName,
					goodsList:carts,
					physical:1,//虚实物 0虚物 1实物
					remarks:"",//订单备注
				}]
				localStorage.setItem("orderList",JSON.stringify(orderList))
				this.$router.push({ path: '/payPage', query: {ori:this.type,groupType:0 }})
			}else{
				//加入购物车
				var obj = {
					skuName:this.dataobj.skuList[this.skuindex1].skuName,
					skuImg:this.dataobj.productImageUrl,
					skuNum:this.dataobj.skuList[this.skuindex1].skuNum,
					cartId:null,
					goodsUuid:this.dataobj.goodsUuid,
					goodsobj:this.dataobj,
					goodsNum:this.skunum,
					goodsPirce:this.dataobj.finalPrice,
					batchid:this.batchid,
				}
				this.handleputCart(obj)
			}
		},
		//加入购物车操作
		handleputCart(obj) {
			var _this = this
			var params = {}
			params["userUuid"] = this.uuid
			params["userPhone"] = this.phone
			params["choiceUnit"] = obj.skuName+":"+obj.skuNum
			params["goodsUuid"] = obj.goodsUuid
			params["goodsNum"] = obj.goodsNum
			params["type"] = 0 //0增加购物车 1设置商品数
			params["batchid"] = obj.batchid
			this.$http.post('medicine/medicineDrugsCart/putCart', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					//刷新购物车购物车
					localStorage.setItem("iffreshcar","true")
					_this.$message.success("加入购物车成功~")
					//获取购物车长度
					_this.getCartLength()
					// _this.$router.push({
					// 	path: "/showCar"
					// });
				} else {
					_this.$message.error("加入购物车失败~")
					// _this.$confirm(res.message, '提示', {
					// 	confirmButtonText: '确定',
					// 	cancelButtonText: '取消',
					// 	type: 'warning'
					// }).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss" scoped></style>
